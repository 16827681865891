class ImageLoader {
	constructor() {
		this.requestQueue = [];
		this.loadedImages = new Set(); // Історія завантажених зображень
		this.isProcessingQueue = false;
		this.observer = null;
		this.inQueue = new Set(); // Трекінг зображень у черзі
        this.supportsWebP = false; // Параметр для збереження підтримки WebP

        // Перевірка підтримки WebP
		this.checkWebPSupport();
	}

    checkWebPSupport() {
		const webP = new Image();
		webP.onload = webP.onerror = () => {
			this.supportsWebP = webP.height === 2;
		};
		webP.src = "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
	}

	init(selector) {
		// Створюємо IntersectionObserver
		this.observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					this.enqueueImageRequest(entry.target);
					observer.unobserve(entry.target); // Відключаємо спостереження
				}
			});
		}, {
			threshold: 0.1
		});

		// Починаємо спостерігати за зображеннями
		document.querySelectorAll(selector)?.forEach(img => {
			this.observer.observe(img);
		});
	}

	enqueueImageRequest(img) {
		const src = img.src.replace("placeholder", "compressed");

		// Перевіряємо, чи зображення вже в черзі або завантажене
		if (this.loadedImages.has(src) || this.inQueue.has(src)) {
			return;
		}

		this.requestQueue.push(img);
		this.inQueue.add(src); // Додаємо до трекінгу черги
		this.processQueue();
	}

	processQueue() {
		if (this.isProcessingQueue) return; // Уникаємо паралельної обробки
		this.isProcessingQueue = true;

		const processNext = () => {
			if (this.requestQueue.length === 0) {
				this.isProcessingQueue = false;
				return;
			}

			const img = this.requestQueue.shift();
			this.inQueue.delete(img.src.replace("placeholder", "compressed")); // Видаляємо з трекінгу черги
            if (img.src === img.src.replace("placeholder", "compressed")) {
                this.loadedImages.add(img.src)
            } else {
                this.changeImageSrc(img).finally(processNext);
            }
		};

		processNext();
	}

	changeImageSrc(img) {
        const info = img.id.split("-");
        const index_slider = info[info.length - 2];
        const index_image = info[info.length - 1];
        
        const creative_src = `creative-src-${index_slider}-${index_image}`;
        const creative_src_webp = `creative-src-webp-${index_slider}-${index_image}`;
        const creative_src_resized_webp = `creative-src-resized-webp-${index_slider}-${index_image}`;
        const creative_src_resized = `creative-src-resized-${index_slider}-${index_image}`;

        const el_creative_src = document.getElementById(creative_src);
        const el_creative_src_webp = document.getElementById(creative_src_webp);
        const el_creative_src_resized_webp = document.getElementById(creative_src_resized_webp);
        const el_creative_src_resized = document.getElementById(creative_src_resized);

		const src = img.src.replace("placeholder", "compressed");
		const src_webp = src.replace("jpg", "webp");
        const src_resized = img.src.replace("placeholder", "resized");
        const src_resized_webp = src_resized.replace("jpg", "webp");

        const imageUrl = this.supportsWebP ? src_webp : src;
        
		return fetch(imageUrl)
			.then(response => {
				if (response.ok) {
					el_creative_src.srcset = src;
                    el_creative_src_webp.srcset = src_webp;
                    el_creative_src_resized_webp.srcset = src_resized_webp;
                    el_creative_src_resized.src = src_resized;
					this.loadedImages.add(imageUrl); // Додаємо в історію завантажених
                    // img.closest(".swiper-slide-creo-wrap").querySelector(".creative-to-remove").remove()
				} else {
					console.warn(`Image failed to load: ${src}`);
				}
			})
			.catch(error => {
				console.error("Failed to load image", error);
			});
	}
}

export default ImageLoader